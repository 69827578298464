import AvatarGroup from "@components/AvatarGroup/AvatarGroup"
import CenteredContent from "@components/CenteredContent/CenteredContent"
import mailIcon from "@components/layout/Footer/assets/mail.svg"
import phoneIcon from "@components/layout/Footer/assets/phone.svg"
import linkedinIcon from "@components/layout/Footer/assets/linkedin.svg"

import React from "react"

import "./ContactHero.scss"
import { Body } from "@components/ui/Typography/Typography"
import { Link } from "gatsby"

const ContactHero = () => {
  return (
    <section className="hero-contact-container">
      <CenteredContent>
        <div className="hero-contact-avatars">
          <AvatarGroup variant="large" />
        </div>

        <h1 className="hero-contact-heading">Work Together !</h1>

        <div className="hero-contact-ctas">
          <div className="hero-contact-topFooterContentLink">
            <img src={mailIcon} />
            <Body>hello@kezios.fr</Body>
          </div>
          <div className="hero-contact-topFooterContentLink">
            <img src={phoneIcon} />
            <Body>06 02 60 47 38</Body>
          </div>
          <Link to={"https://fr.linkedin.com/company/kezios"}>
            <div className="hero-contact-topFooterContentLink">
              <img src={linkedinIcon} />
              <Body>Kezios</Body>
            </div>
          </Link>
        </div>
      </CenteredContent>
    </section>
  )
}

export default ContactHero
