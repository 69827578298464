export type PartialDevToUserData = {
  username: string
  profile_image: string
  profile_image_90: string
}

export const UserIdsArray = [
  "alexandre",
  "adrian",
  "justin",
  "arthur",
  "olivier",
] as const
export type UserIds = typeof UserIdsArray[number]
export type User = {
  firstname: string
  lastname: string
  title: string
  pictureFileLink: JSX.Element
  order: number
  devtoUsername?: string
}
