import * as React from "react"
import Layout from "@components/layout/layout"
import ContactHero from "@landingsParts/Contact/ContactHero/ContactHero"
import SEO from "@components/SEO/SEO"

// markup
const ContactPage = () => {
  return (
    <main>
      <SEO title="Kezios - Contact" lang="fr" />
      <title>Kezios - Contact</title>
      <Layout withoutContact>
        <ContactHero />
      </Layout>
    </main>
  )
}

export default ContactPage
