import Avatar, { users } from "@components/ui/Avatar/Avatar"
import React from "react"
import { UserIds, UserIdsArray } from "@local-types/User"

import "./AvatarGroup.scss"

export type AvatarGroupProps = {
  variant?: "small" | "medium" | "large"
  included?: UserIds[]
}

const AvatarGroup = ({ variant = "medium", included }: AvatarGroupProps) => {
  const displayedUsersIds: UserIds[] = included
    ? [...UserIdsArray].filter(id => included.includes(id))
    : [...UserIdsArray]

  return (
    <div className="avatar-group-container">
      {displayedUsersIds
        .sort((a, b) => (users[a].order > users[b].order ? 1 : -1))
        .map(key => (
          <Avatar key={key} variant={variant} userId={key as UserIds} />
        ))}
    </div>
  )
}

export default AvatarGroup
